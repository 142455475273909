import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { ensureUser } from '../../util/data';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './MerchantUserCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;


const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const MerchantUserCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    renderSizes,
    merchant,
    setActiveListing,
    showAuthorInfo,
  } = props;
  
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureUser(merchant);
  const authorName = user?.attributes?.profile?.displayName;
  const firstImage = user && user.profileImage;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'square-small',
  } = {};  
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  return (
    <NamedLink className={classes} name="ProfilePage" params={{ id: user.id.uuid }}
    >
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={"title"}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>

      <div className={css.info}>
        <div className={css.price}>
          <div className={css.priceValue} title={"priceTitle"}>
            "ppp"
          </div>
        </div>
        <div className={css.mainInfo}>
          {/* <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div> */}
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div>
      </div>
    </NamedLink>
  );
};

MerchantUserCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

MerchantUserCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(MerchantUserCardComponent);
